//
//layout spacings
//



//for items like launchpad items, where they're spaced apart, but we want
//to maintain the vertical padding on the top and the bottom
.spaced-item-area-s0{
	+ .spaced-item-area-s0{
		margin-top: $v-space-margin-s0;
	}
	margin-top: -#{$v-space-margin-s0};
	margin-bottom: -#{$v-space-margin-s0};

	.spaced-item{
		margin-top: $v-space-margin-s0;
		margin-bottom: $v-space-margin-s0;
	}
}

.spaced-item-area-s1{
	+ .spaced-item-area-s0{
		margin-top: $v-space-margin-s0;
	}
	margin-top: -#{$v-space-margin-s1};
	margin-bottom: -#{$v-space-margin-s1};

	.spaced-item{
		margin-top: $v-space-margin-s1;
		margin-bottom: $v-space-margin-s1;
	}
}

.spaced-item-area-s2{
	+ .spaced-item-area-s0{
		margin-top: $v-space-margin-s0;
	}
	margin-top: -#{$v-space-margin-s2};
	margin-bottom: -#{$v-space-margin-s2};

	.spaced-item{
		margin-top: $v-space-margin-s2;
		margin-bottom: $v-space-margin-s2;
	}
}
.spaced-item-area-s3{
	margin-top: -#{$v-space-margin-s3};
	margin-bottom: -#{$v-space-margin-s3};

	.spaced-item{
		margin-top: $v-space-margin-s3;
		margin-bottom: $v-space-margin-s3;
	}
}
