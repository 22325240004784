.bulletin-trending-article{
	// display: flex;
	// align-items: center;
	// align-content: center;
	position: relative;
	padding-left: px-to-rem(156px);
	min-height: 120px;
	.bulletin-trending-img{
		position: absolute;
		top: 50%;
		left:$col-gutter;
		transform: translateY(-50%);
		width: px-to-rem(120px);
		margin: 0 1.25rem 1.25rem 0;
	}
}

.bulletin-past-issues-slider-outer{
	overflow: hidden;
}
.bulletin-past-issues-slider{

	.slick-list{
		overflow: visible;
	}

	.past-issue-holder{
		max-width: px-to-rem(368px);
		padding-right: 2.875rem;
		padding-left: 2.875rem;
		margin:auto;
		text-align: center;
	}

	.past-issue-title{
		color: $color-headings;
		display: block;
		margin-bottom: .375rem;
		position: relative;
		font-size: 1.5rem;
		z-index: 1;
	}

	.past-issue-cover-holder{
		position: relative;
		z-index: 2;
		display: block;
	}

	@include breakpoint-min(md){
		// $safari-render-fix: translate3d(0,0,0);
		$away-1-scale: scale3d(1.57,1.57,1);
		$away-2-scale: scale3d(1.33,1.33,1);
		$away-away-scale: scale3d(1,1,1);
		$centered: scale3d(2,2,1);

		// overflow: hidden;
		//because SAFARI and scale rendering issues
		width:200%;
		transform: scale(.5);
		margin-left:-50%;

		// padding-top: 10rem;
		// padding-bottom: 9rem;

		.past-issue-holder{
			max-width: none;
			padding-right: 0;
			padding-left: 0;
		}

		.slick-slide{
			position: relative;
			text-align: center;
			transition: 500ms all ease-in-out;
			opacity:0;
			transform: $away-away-scale translate(0%,17%);
			backface-visibility: hidden;
			-webkit-font-smoothing: subpixel-antialiased;

			&.slick-center,
			&.pos-prev-1,
			&.pos-prev-2,
			&.pos-next-1,
			&.pos-next-2{
				opacity:1;
			}

			.past-issue-title{
				opacity: 0;
				// font-size: .875rem;
			}

			&.slick-center{
				transform: $centered;
				z-index: 3;

				.past-issue-title{
					opacity: 1;
				}

			}
			&.pos-prev-1{z-index: 2; transform: $away-1-scale translate(15%,7.5%) }
			&.pos-prev-2{z-index: 1; transform: $away-2-scale translate(50%,15%)}

			&.pos-next-1{z-index: 2; transform: $away-1-scale translate(-15%,7.5%)}
			&.pos-next-2{z-index: 1; transform: $away-2-scale translate(-50%,15%)}

		}

		a{
			display: inline-block;
		}

	}
	img{
		max-width: 100%;
	}
	.slick-arrow{
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;
		font-size: 1.5rem;
		background-color: transparent;
		padding: 3rem 0;//bigger tap zone
		&:hover{
			background-color: transparent;
		}
	}

	.slick-prev{
		// margin-right: -.75rem;
		left: 0;
		left:auto;
		padding-right: 2rem;
	}
	.slick-next{
		// margin-left: -.75rem;
		right: 0%;
		padding-left: 2rem;
	}
}

.bulletin-article-callout,
.bulletin-article-quote{
	margin:  1.5rem 0;
	position: relative;
	font-size: 1.5rem;
	color: $color-headings;
	line-height: 1.4;
	font-family: $font-headings;
	font-weight: 300;
	p{
		margin-bottom:.875em;
	}

	footer{
		font-size: 1.125rem;
		color: $color-font-body;
	}
}

.bulletin-article-callout{
	@include breakpoint-min(xl){
		max-width: 286px;
	}
}

.bulletin-article-quote {
	margin: 3rem 0 3rem;
	padding-left: 2.25rem;
	// font-family: $font-headings;
	// font-weight: 400;
	&:before {
		content: ' ';
		display: block;
		width: 1.25rem;
		height: 100%;
		background: $color-green;
		position: absolute;
		left: 0;
		top: .22em;
	}
}

.bulletin-article-callout{
	text-align: center;
	padding: 1.5rem 1.25rem;
	background: linear-gradient(135deg, $color-lt-gray,$white);
	border-top: 1px solid $color-orange;
	border-bottom: 1px solid $color-orange;

	//if its a figure
	figcaption{
		text-align: left;
		margin-top: 1.625rem;
		font-size: 1rem;
		color: $color-font-body;
	}

	.bulletin-article-callout-inner{
		max-width: 480px;
		margin: auto;
	}

	@include clear-after;
}

.bulletin-tags{
	margin: 1rem 0;
	> ul{
		vertical-align: middle;
	}
	strong{
		font-weight: 600;
		font-size: 1.125rem;
		vertical-align: middle;
		padding-bottom: .1rem;
	}
	li{
		margin: .2rem .375rem;
	}
}


.bulletin-related-list{
	li{
		padding-left: 2.75rem;
		position: relative;
		margin: 1rem 0;
		&:before{
			content: ' ';
			display: block;
			width: 20px;
			height: 20px;
			background: url('../images/bulletin/bulletin-li-bullet.png') left top no-repeat;
			background-size: 20px;
			position: absolute;
			left: .5rem;
			top:.3rem;
		}

		a{
			font-weight: 600;
		}
	}
}

.bulletin-text-tag{
	width: px-to-rem(82px);
	vertical-align: baseline;
	display: inline-block;
}

@keyframes down-arrow-animation{
	0%{
		transform: translate3d(-50%,0,0);
	}

	25%{
		transform: translate3d(-50%,4px,0);
	}

	75%{
		transform: translate3d(-50%,-1px,0);
	}
}
