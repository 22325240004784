.event-tile, .fluid-tile, .square-tile, .ways-learn-tile {
  border: 1px solid $color-gray;
  padding: .7em;
  padding-bottom: 2.5em;
  position: relative;
  -webkit-transition: 120ms border-color ease-out;
  transition: 120ms border-color ease-out;
}

.event-tile:hover, .fluid-tile:hover {
  border-color: $color-orange;
}

.event-tile h3 {
  font-size: .9375em;
  font-weight: 600;
}

.related-articles h3 {
  font-size: .9375em;
  font-weight: 600;
  margin-top: 0;
}

.event-tile h3 a, .related-articles h3 a, .article-list li a, .event-tile h3 a, .related-articles h3 a, .article-list li a {
  color: $color-royal;
}

.jump-to {
  a:not(.scroll-to-link) {
    color: $color-royal;
  }
  li {
    margin-bottom: 1rem;
  }
}

.related-articles li {
  padding: 0 1em 0.2em 0;
}

.tile-bottom-link {
  position: absolute;
  bottom: .875em;
  left: .7em;
}

.tile-bottom-link2 {
  color: $color-sky !important;
}

.article-list {
  h3 {
    font-size: .875em;
    font-weight: 600;
  }
  li {
    margin-bottom: .675em;
  }
}

.fluid-tiles > section {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.fluid-tile {
  > {
    div, aside {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }
  padding: 0;
  max-width: 360px;
  margin: auto;
}

.ways-learn-tile {
  padding: 0;
  max-width: 360px;
  margin: auto;
}

.fluid-header, .ways-learn-header, .select-item {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1em;
  background: rgba(10, 65, 99, 0.9);
  width: 100%;
}

.ways-learn-header, .select-item {
  padding-top: .6em;
  padding-bottom: .6em;
  position: static;
  margin-bottom: 1em;
}

.fluid-header h3, .ways-learn-header h3, .select-item h3 {
  margin: 0;
  color: $white;
}

.fluid-header a, .ways-learn-header a, .select-item a {
  color: inherit;
  text-decoration: none;
}

.fluid-header a:after, .ways-learn-header a:after, .select-item a:after {
  color: $color-sky;
}

.fluid-tile {
  figure {
    margin: 0;
    padding: 0;
  }
  img {
    display: block;
    width: 100%;
  }
}

.fluid-tile-no-image {
  .fluid-header {
    position: static;
  }
  .text-content {
    padding: 1em;
    padding-bottom: 3.2em;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.ways-learn-tile {
  min-height: 6em;
  padding-bottom: 1em;
}

.flex-container > section > .event-tile,
.recent-news-slider .event-tile,
.event-tile-area .event-tile,
.fluid-tile-no-image,
.square-tile-area .square-tile {
  min-height: 100%;
}
