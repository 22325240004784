
@media only screen {
	@include placeholder-text {
		color: $color-dark-green;
		opacity: 1;
		font-weight: 700;
	}
}

:hover, :active, :focus, * {
	outline: none;
	outline-style: none;
	outline-color:
	transparent !important;
}
.breakpoint-helper {
	position: fixed;
	z-index: 5000;
	color: $white;
	background: #444;
	padding: 1ex;
	left: 0;
	top: 0;
	font-size: .75em;
	opacity: .75;
}

label,
.tool-tip *,
.no-select,
.button,
button,
input[type=submit],
.has-button a {
	-ms-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}


.img-icon-32h {
	display: none;
	vertical-align: middle;
	margin-right: .6rem;
	height: 32px;
}
.overview-img-holder {
	padding: 0 12px 1rem;
	max-width: 360px;
	width: 100%;
	img { width: 100%; }
}



.list-group-item-danger,
.field-validation-error {
	color: red;
}

.bg-black .list-group-item-danger,
.bg-black .field-validation-error {
	color: white;
}


a .icon-external-site {
	color: inherit !important;
}

.icon-external-site:before {
	margin-left: .4ex;
	display: inline-block;
}

h1 .icon-external-site,
h2 .icon-external-site,
h3 .icon-external-site,
h4 .icon-external-site {
	font-size: .625em;
}

figure {
	position: relative;
	a.external-link-added .icon-external-site {
		position: absolute;
		bottom: 6px;
		right: 6px;
	}
}

@media only screen and (min-width: 641px) {
	.overview-img-holder {
		max-width: 235px;
	}
	.icons-left-ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 3.5rem;
		.img-icon-32h {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
		}
		li {
			position: relative;
			margin: 1.75rem 0;
			padding-left: 36px;
		}
	}
}

.img-icon {
	height: 32px;
	width: 32px;
	vertical-align: baseline;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: contain;
}


img { height: auto; width: auto; }
body{ width: 100%; height: 100%; }
body {
	background: $white;
	font-size: 100%;
	color: $color-font-body;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, .font-inherit-body { font-family: $font-body; }
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
.heading-font {
	font-family: $font-headings;
	font-weight: 400;
	line-height: 1.2;
	color: $color-headings;
	&:first-child{
		margin-top: 0;
	}
}
h1,.h1 { font-size: 2em; margin: .67em 0; font-weight: 300; }
h2,.h2 { font-size: 1.5em; margin: 1em 0 .825em; font-weight: 400; }
h3,.h3 { font-size: 1.257em; margin: 1em 0 .8em; }
h4,.h4 { font-size: 1.0625em; margin: .83em 0; font-weight: 400; }
h5,.h5 { font-size: 1.0625em; margin: .73em 0 .83; font-weight: 700; text-transform: uppercase; color: $color-font-body; }
h6,.h6 { font-size: .67em; margin: 2.33em 0 }

.heading-font{
	color: $color-font-body;
}

@include breakpoint-min(lg){
	h1,.h1 { font-size: 3em; margin: .67em 0 }
	h2,.h2 { font-size: 1.5em; }
	h3,.h3 { font-size: 1.25em; margin: .6em 0; }
	h4,.h4{ font-size: 1.0625em; margin: .83em 0 }
	h5,.h5 { font-size: 1.0625em; margin: 1.67em 0 }
	h6,.h6 { font-size: .67em; margin: 2.33em 0 }
}

.heading-no-margin{
	h1,.h1, h2,.h2, h3,.h3, h4,.h4, h5,.h5, h6,.h6 {
		margin-top:0;
		margin-bottom:0;
	}
}

.font-weight-normal { font-weight: normal; }
.line-height-extra { line-height: 1.75; }
.color-inherit-parent,
.color-inherit-parent:hover {
	color: inherit;
}
/*#image right and left#*/

.img-right, .img-left { display: block; }
.img-right a,  .img-left a { max-width: 100%; }
.img-right img,  .img-left img { max-width: 100%; display: block; float: left; }


.no-records, .no-record {
	color: #aaa;
	font-style: italic;
}

p {
	font-size: inherit;
	margin: 0 0 1em;
}

.has-sky-blue-link a,
a.sky-blue-link,
a, input[type=submit], button {
	color: $color-sky;
	text-decoration: none;
	-webkit-transition: all 100ms;
	transition: all 100ms;
}

.inverse-text {
	&,*{
		color: $white;
	}
	&.has-sky-blue-link a,
	.has-sky-blue-link a,
	a.sky-blue-link{
		&:hover{
			color: tint($color-sky,30);
		}
	}
}


a:hover {
	color: #1789a7;
}

.no-decoration {
	text-decoration: none;
}

.has-orange-link a,
a.orange-link,
.text-orange a,
.orange-link {
	color: $color-orange;

	&:hover {
		color: $color-dk-orange;
	}
}


.has-dark-blue-anchor a,
.dark-blue-anchor {
	color: $color-navy-2;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.has-green-link a,
.has-green-anchor a,
.green-link {
	color: $color-green;
	// font-weight: 600;
	&:hover{
		color: $color-dark-green;
	}
}

.read-more-link {
	text-decoration: none;
	&:after {
		font-size: .5rem;
		margin-left: .4rem;
	}
}

.read-more-arrow {
	&:after {
		font-size: .5rem;
		margin-left: .4rem;
	}
	color: $color-sky;
}

.scroll-to-link:after {
	margin-left: .75ex;
	transform: rotate(90deg);
	display: inline-block;
	font-size: .55rem;
	vertical-align: baseline;
}

.go-back-link:before {
	font-size: .5em;
	margin-right: .75ex;
}

.continue-link:after {
	font-size: .5em;
	margin-right: .75ex;
	margin-right: 0;
	margin-left: 0.75ex;
}

.no-outline {
	&:active, &:focus {
		outline: 0;
	}
	outline: 0;
}

.header-with-opts-links {
	> * {
		display: inline-block;
		vertical-align: baseline;
	}
	h2 {
		margin-right: .5ex;
		display: inline-block;
	}
	span {
		display: block;
		margin-bottom: 0.5rem;
	}
}

#to-top-btn {
	color: $color-green;
	font-weight: 600;
	font-weight: 300;
	&:hover{
		color: $color-dark-green;
	}
}


ol.ol-bold-numbers,
.ol-bold-numbers ol {
	margin: 0 0 .75em;
	padding: 0;
	counter-reset: item;

	> li {
		margin-bottom: .875em;
		padding: 0 0 0 2em;
		text-indent: -2em;
		list-style-type: none;
		counter-increment: item;

		&:before {
			display: inline-block;
			width: 1em;
			padding-right: 0.5em;
			font-weight: bold;
			text-align: right;
			content: counter(item) ".";
		}
	}
}

li {
	margin-bottom: 0.4em;
}

.hide {
	display: none;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}


.inline-ul {
	ul {
		display: inline-block;
		list-style: none;
		padding: 0;
		margin: 0;
	}
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
		list-style: inherit;
		width: auto;
		margin-bottom: 0;
	}
}

.block-li {
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		display: block;
	}
}

ul.no-bullet-ul,
.has-no-bullet-ul ul{

	list-style: none;
	padding: 0;
	li {
		list-style: inherit;
	}
}

ul.two-column-ul,
.two-column-ul ul {
	display: block;
	padding: 0;
	margin: 0;
	list-style: none;
	@include breakpoint-min(lg){
		width: 100%;
		letter-spacing: -.3em;

		li {
			list-style: inherit;
			width: 50%;
			display: inline-block;
			letter-spacing: 0;
			vertical-align: top;
			padding-right: 1rem;
		}
	}
}


.color-navy{
	color: $color-navy;
}


.clear{
	&-right{clear:right;}
	&-left{clear:left;}
}


.inline-img-w-caption{
	display: block;
	text-align: center;
	margin: px-to-rem(34px) auto px-to-rem(30px);
	max-width: px-to-rem(350px);
	img{
		display: inline-block;
	}
	figcaption{
		display: inline-block;
		text-align: left;
		padding: .625rem 0 0;
		line-height: 1.3;
		font-size: px-to-rem(12px);
	}
}
