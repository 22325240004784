//boxes

.box, .boxed-input, .box-account { background: $color-lt-gray; width: 100%; padding: 1.1rem; margin-bottom: 1.25rem; border: $color-gray 1px solid; min-height: 5rem; }
.box.acr-blue *,  .box.bg-acr-blue h3 { color: $white; }
.box.bg-acr-blue h3:after { content: ''; display: block; width: 182px; margin: auto; margin-top: .5em; max-width: 100%; height: 1px; background: #697d8b; }
.box:after { content: ""; display: block; clear: both; }
.in-content-callout { margin: auto; border: 0; }
.box-account { border-color: $color-orange; background: none; margin-bottom: 0; }
.box-account:after { content: ''; display: block; clear: both; }
.box.box-white { background: $white; }
.boxed-input { background: $white; padding: .25rem 2rem .25rem 1.1rem; position: relative; -webkit-transition: 120ms border ease-out; transition: 120ms border ease-out; vertical-align: middle; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.boxed-input:hover,  .boxed-input:active,  .boxed-input:hover .acr-checkbox i,  .boxed-input:hover .acr-radio i { border-color: $color-orange; }
.boxed-input .acr-checkbox,  .boxed-input .acr-radio { vertical-align: middle; display: inline-block; }
