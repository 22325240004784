//
// Breakpoints
// --------------------------------------------------

$breakpoints-min: (
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl
);

//small to large for cascade reasons
$breakpoints-max: (
  xl: $screen-xl-max,
  lg: $screen-lg-max,
  md: $screen-md-max,
  sm: $screen-sm-max
);

@mixin breakpoint-min($breakpoint) {
  @media only screen and (min-width: map-get($breakpoints-min, $breakpoint)) {
    @content;
  }
}

@mixin breakpoint-max($breakpoint) {
  @media only screen and (max-width: map-get($breakpoints-max, $breakpoint)) {
    @content;
  }
}

@mixin breakpoint-min-max($bkpt-min,$bkpt-max) {
  @media only screen and (min-width: map-get($breakpoints-min, $bkpt-min)) and (max-width: map-get($breakpoints-max, $bkpt-max)){
    @content;
  }
}
