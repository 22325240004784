.directory-area {
  justify-content: center;
}

.directory-item {
  line-height: 1.7;
  padding-left: .875em;
  padding-right: .875em;
}

.directory-img {
  margin-bottom: 1.3em;
  img {
    max-width: 100%;
  }
}

.directory-contact {
  margin-top: 0.5em;
}
