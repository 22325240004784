@media only screen and (max-width: 1024px),
only screen and (min-device-width: 768px) and (max-device-width: 1024px)
// not all and (min-device-width: 1112px) and (orientation: landscape)
{
  .top-utility-nav {
    display: none;
  }

  #main-nav-area .content {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 1003;
  }

  #site-header {
    border-bottom: 1px solid #a0b5c3;
  }

  #main-nav-holder {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    a {
      color: $color-navy-2;
      &:hover {
        color: #196899;
      }
    }
    display: none;
    font-family: inherit;
  }

  #mobile-nav-btn {
    border: 1px solid transparent;
    position: relative;
    border-bottom: 0;
    display: inline-block;
    float: right;
    height: 56px;
    width: 48px;
    padding: 0 0 .85em;
    text-transform: uppercase;
    text-align: center;
    font-size: inherit;
    -moz-transition: all 120ms;
    -webkit-transition: all 120ms;
    transition: all 120ms;
    margin-left: 10px;
  }

  #mobile-nav-btn-inner {
    height: 26px;
    position: relative;
    width: 30px;
    float: right;
    div {
      background: $white;
      height: 3px;
      width: 100%;
      max-width: 30px;
      -webkit-transition: all 180ms cubic-bezier(0.04, 0.55, 0.47, 1);
      transition: all 180ms cubic-bezier(0.04, 0.55, 0.47, 1);
    }
  }

  #mobile-nav-btn div + div {
    margin-top: .45em;
  }

  #main-nav-holder {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: auto;
  }

  #mobile-nav-btn {
    // &.menu-opened {
    //   border-color: #00806f;
    //   background-color: #00bfa5;
    //   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3);
    // }
    &:after {
      content: 'MENU';
      font-size: 11px;
      display: block;
      color: $white;
      text-align: rigth;
      float: right;
    }
  }

  .menu-opened {
    #mobile-nav-btn {
      &:after {
        content: 'CLOSE';
      }
      div + div {
        margin-top: 0;
      }
    }
    .nav-mid-bar {
      opacity: 0;
      display: none;
    }
    #mobile-nav-btn {
      .nav-top-bar {
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: 50%;
        margin: 0;
      }
      .nav-bot-bar {
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: 50%;
        margin: 0;
      }
    }
  }

  #main-nav {
    position: relative;
    z-index: 1010;
    transition: 200ms all ease-out;
    opacity: 1;
    &.hide-main-nav {
      opacity: 0;
    }
  }

  .menu-opened #main-nav-holder {
    display: block;
    padding: 0 0 .8em;
    margin: 0;
    z-index: 1010;
    border-bottom: 2px solid $color-orange;
    background: $color-lt-gray;
    > ul {
      padding: 0;
      margin: 1em 0;
    }
  }

  #main-nav li {
    position: relative;
    font-size: 1rem;
  }

  #main-nav-holder > ul > li {
    vertical-align: middle;
    text-decoration: none;
    display: block;
    line-height: 1.1;
    white-space: nowrap;
    position: relative;
    margin: 0;
    font-weight: 600;
    a {
      display: inline-block;
      padding: .75rem 1.5rem;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 30px);
    }
    h3 {
      white-space: nowrap;
      margin: 0;
      a {
        padding-left: 0;
        margin-left: .5rem;
        vertical-align: middle;
      }
    }
  }

  #main-nav {
    .next-nav-level {
      display: inline-block;
      text-align: center;
      position: absolute;
      top: 0;
      right: 12px;
      font-size: .75rem;
      padding: 14px 15px 14px 15px;
      cursor: pointer;
    }
    .hide-nav-elem {
      display: none;
    }
    .show-nav-elem {
      > ul, .main-nav-l2 {
        display: block;
      }
      > a {
        display: none;
      }
    }
    .active-parent > .next-nav-level {
      display: none;
    }
    .back-btn {
      display: inline-block;
      padding: 12px;
      margin-left: 1rem;
      font-size: 1.1em;
      color: $color-green;
      vertical-align: middle;
    }
  }

  #m-nav-bottom:before {
    content: '';
    display: block;
    margin-right: .875em;
    margin-left: .875em;
    height: 1em;
    border-top: 2px solid #e6e6e6;
  }

  #main-nav-holder #m-utility-top.mobile-nav-bottom-links ul {
    display: table;
    table-layout: fixed;
    text-align: center;
    clear: both;
  }

  #m-utility-top .acr-icon {
    margin-right: .8ex;
  }

  #main-nav-holder {
    #m-utility-bottom.mobile-nav-bottom-links ul {
      display: block;
    }
    .mobile-nav-bottom-links a {
      overflow: visible;
      padding: 4px 0;
      font-weight: 400;
    }
  }
}

@include breakpoint-max(md){
  .site-header-ad-visible main[role='main'] {
    margin-top: 214px;
  }

  .tablet-nav-only {
    display: none;
  }

  #main-nav-dropdown {
    width: 100%;
    width: calc(100% - 1.5em);
    float: right;
    overflow: hidden;
    li a {
      color: $color-sky;
      &:hover, &:active {
        color: #2292b2;
      }
    }
  }
}


@media only screen and (max-width: 960px) {
	.event-tile h3,  .related-articles h3 { margin-bottom: 0; }
	.event-tile { padding-bottom: 3.4em }
	.ways-learn-tile { padding-bottom: .4em }
}



.mobile-nav-bottom-links .icon-external-site:after {
    content: none;
}
