/*#font-faces#*/

@font-face {
  font-family: 'icomoon';
  src: url($font-path+'/fonts/icomoon.ttf?agpjvh') format('truetype'),
	  url($font-path+'/fonts/icomoon.woff?agpjvh') format('woff'),
	  url($font-path+'/fonts/icomoon.svg?agpjvh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.acr-icon, .form-header-search-submit-btn,
.icon-external-site:after,
.dropdown.icon, .read-more-link:after,
.read-more-arrow:after, .go-back-link:before,
.continue-link:after, .accordion-header:after,
.cart-header:after, .filter-cat-header:after, .share-link:before,
.reccommend-link:before, .bookmark-link:before,
.tool-tip:after, .ui-icon-circle-triangle-w:before,
.ui-icon-circle-triangle-e:after,
#main-nav .has-children > a:after,
#cboxPrevious, #cboxNext, #cboxClose,
#to-top-btn:before, .scroll-to-link:after,
.slick-arrow {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  line-height: 1;
  // Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-skinny-left:before, .slick-prev:before { content: "\e900"; }
.icon-arrow-skinny-right:before, .slick-next:before { content: "\e901"; }
.icon-blogger:before { content: "\e902"; }
.icon-carrot-down:before { content: "\e903"; }
.icon-carrot-left:before { content: "\e904"; }
.icon-carrot-right:before { content: "\e905"; }
.icon-carrot-up:before { content: "\e906"; }
.icon-check:before { content: "\e907"; }
.icon-chevron-sm-right:before, .read-more-link:after,
.read-more-arrow:after, .scroll-to-link:after { content: "\e908"; font-size: .625rem; }
.icon-db-chevron-down:before { content: "\e909"; }
.icon-db-chevron-up:before { content: "\e90a"; }
.icon-db-chevron2-left:before, .go-back-link:before { content: "\e90b"; }
.icon-db-chevron2-right:before, .continue-link:after { content: "\e90c"; }
.icon-facebook:before { content: "\e90d"; }
.icon-instagram:before { content: "\e90e"; }
.icon-linkedin:before { content: "\e90f"; }
.icon-rss:before { content: "\e910"; }
.icon-search:before, .form-header-search-submit-btn:before { content: "\e911"; }
.icon-shake-hands:before { content: "\e912"; }
.icon-shop:before { content: "\e913"; }
.icon-sort-down:before, .dropdown.icon:before { content: "\e914"; }
.icon-sort-up:before { content: "\e915"; }
.icon-external-site:after,
.icon-target-blank:before { content: "\e916"; }
.icon-twitter:before { content: "\e917"; }
.icon-youtube:before { content: "\e918"; }
.icon-videos:before { content: "\e919"; }
.icon-alarm-clock:before { content: "\e91a"; }
.icon-document:before { content: "\e91b"; }
.icon-chevron2-right:before { content: "\e91e"; }
.icon-chevron2-left:before { content: "\e91c"; }
.icon-cancel:before, #cboxClose:before { content: "\e91d"; }
.icon-email:before { content: "\e91f"; }
.icon-phone:before { content: "\e920"; }
.icon-star:before, .bookmark-link:before { content: "\e921"; }
.icon-to-right-arrow:before, .reccommend-link:before { content: "\e922"; }
.icon-share:before, .share-link:before { content: "\e923"; }
.icon-arrow-right:before, .accordion-header:after, .cart-header:after, .filter-cat-header:after, .ui-icon-circle-triangle-e:after, #to-top-btn:before, #cboxNext:before { content: "\e924"; }
.icon-arrow-left:before, .ui-icon-circle-triangle-w:before, #cboxPrevious:before { content: "\e925"; }
.icon-heart:before { content: "\e926"; }
.icon-info-circle:before, .tool-tip.tip-info:after { content: "\e927"; }
.icon-question-mark-circle:before, .tool-tip.tip-question:after { content: "\e928"; }
.icon-info-circle-2:before { content: "\e929"; }
.icon-plus:before { content: "\e92a"; }
.icon-close-envelope:before { content: "\e92b"; }
.icon-gear:before { content: "\e92c"; }
.icon-pencil:before { content: "\e92d"; }
.icon-tag:before { content: "\e92e"; }
.icon-download-folder:before { content: "\e92f"; }
.icon-notify-bell:before { content: "\e930"; }
.icon-group:before { content: "\e931"; }
.icon-printer:before { content: "\e932"; }
.icon-profile:before { content: "\e933"; }
.icon-not:before { content: "\e934"; }
.icon-profile-plus:before { content: "\e935"; }
.icon-tag-outline:before { content: "\e936"; }
.icon-gears:before { content: "\e937"; }
.icon-key:before { content: "\e938"; }
.icon-engage:before { content: "\e939"; }
.icon-catalog:before { content: "\e93a"; }
.icon-facebook,
.icon-twitter,
.icon-instagram,
.icon-linkedin,
.icon-youtube,
.icon-blogger,
.icon-rss {
  color: $white;
  font-size: 1em;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  text-align: center;
  padding-top: .2em;
  border-radius: .13em;
  margin-right: .8ex;
  cursor: pointer;
}

/*
  UPDATED with Extended pack: (https://icomoon.io/#preview-free)
  NOTE: icons repeat from the custom ones (removing them isnt' necessary)
*/
.icon-catalog:before {content: "\e93a";}
.icon-engage:before {content: "\e939";}
.icon-profile:before {content: "\e933";}
.icon-not:before {content: "\e934";}
.icon-profile-plus:before {content: "\e935";}
.icon-tag-outline:before {content: "\e936";}
.icon-specialty:before {content: "\e937";}
.icon-username-password:before {content: "\e938";}
.icon-printer:before {content: "\e932";}
.icon-info-circle-2:before {content: "\e929";}
.icon-plus:before {content: "\e92a";}
.icon-close-envelope:before {content: "\e92b";}
.icon-gear:before {content: "\e92c";}
.icon-pencil:before {content: "\e92d";}
.icon-tag:before {content: "\e92e";}
.icon-download-folder:before {content: "\e92f";}
.icon-notify-bell:before {content: "\e930";}
.icon-group:before {content: "\e931";}
.icon-info-circle:before {content: "\e927";}
.icon-question-mark-circle:before {content: "\e928";}
.icon-heart:before {content: "\e926";}
.icon-arrow-right:before {content: "\e924";}
.icon-arrow-left:before {content: "\e925";}
.icon-share:before {content: "\e923";}
.icon-email:before {content: "\e91f";}
.icon-phone:before {content: "\e920";}
.icon-star:before {content: "\e921";}
.icon-to-right-arrow:before {content: "\e922";}
.icon-chevron2-left:before {content: "\e91c";}
.icon-cancel:before {content: "\e91d";}
.icon-chevron2-right:before {content: "\e91e";}
.icon-videos:before {content: "\e919";}
.icon-alarm-clock:before {content: "\e91a";}
.icon-document:before {content: "\e91b";}
.icon-arrow-skinny-left:before {content: "\e900";}
.icon-arrow-skinny-right:before {content: "\e901";}
/* .icon-blogger1:before { */
.icon-blogger:before {content: "\e902";}
.icon-carrot-down:before {content: "\e903";}
.icon-carrot-left:before {content: "\e904";}
.icon-carrot-right:before {content: "\e905";}
.icon-carrot-up:before {content: "\e906";}
.icon-check:before {content: "\e907";}
.icon-chevron-sm-right:before {content: "\e908";}
.icon-db-chevron-down:before {content: "\e909";}
.icon-db-chevron-up:before {content: "\e90a";}
.icon-db-chevron2-left:before {content: "\e90b";}
.icon-db-chevron2-right:before {content: "\e90c";}
.icon-facebook:before {content: "\e90d";}
.icon-instagram:before {content: "\e90e";}
.icon-linkedin:before {content: "\e90f";}
.icon-rss:before {content: "\e910";}
.icon-search:before {content: "\e911";}
.icon-shake-hands:before {content: "\e912";}
.icon-shop:before {content: "\e913";}
.icon-sort-down:before {content: "\e914";}
.icon-sort-up:before {content: "\e915";}
.icon-target-blank:before {content: "\e916";}
.icon-twitter:before {content: "\e917";}
.icon-youtube:before {content: "\e918";}
.icon-home:before {content: "\e93b";}
.icon-home2:before {content: "\e93c";}
.icon-home3:before {content: "\e93d";}
.icon-office:before {content: "\e93e";}
.icon-newspaper:before {content: "\e93f";}
.icon-pencil-g:before {content: "\e940";}
.icon-pencil2:before {content: "\e941";}
.icon-quill:before {content: "\e942";}
.icon-pen:before {content: "\e943";}
.icon-blog:before {content: "\e944";}
.icon-eyedropper:before {content: "\e945";}
.icon-droplet:before {content: "\e946";}
.icon-paint-format:before {content: "\e947";}
.icon-image:before {content: "\e948";}
.icon-images:before {content: "\e949";}
.icon-camera:before {content: "\e94a";}
.icon-headphones:before {content: "\e94b";}
.icon-music:before {content: "\e94c";}
.icon-play:before {content: "\e94d";}
.icon-film:before {content: "\e94e";}
.icon-video-camera:before {content: "\e94f";}
.icon-dice:before {content: "\e950";}
.icon-pacman:before {content: "\e951";}
.icon-spades:before {content: "\e952";}
.icon-clubs:before {content: "\e953";}
.icon-diamonds:before {content: "\e954";}
.icon-bullhorn:before {content: "\e955";}
.icon-connection:before {content: "\e956";}
.icon-podcast:before {content: "\e957";}
.icon-feed:before {content: "\e958";}
.icon-mic:before {content: "\e959";}
.icon-book:before {content: "\e95a";}
.icon-books:before {content: "\e95b";}
.icon-library:before {content: "\e95c";}
.icon-file-text:before {content: "\e95d";}
.icon-profile-g:before {content: "\e95e";}
.icon-file-empty:before {content: "\e95f";}
.icon-files-empty:before {content: "\e960";}
.icon-file-text2:before {content: "\e961";}
.icon-file-picture:before {content: "\e962";}
.icon-file-music:before {content: "\e963";}
.icon-file-play:before {content: "\e964";}
.icon-file-video:before {content: "\e965";}
.icon-file-zip:before {content: "\e966";}
.icon-copy:before {content: "\e967";}
.icon-paste:before {content: "\e968";}
.icon-stack:before {content: "\e969";}
.icon-folder:before {content: "\e96a";}
.icon-folder-open:before {content: "\e96b";}
.icon-folder-plus:before {content: "\e96c";}
.icon-folder-minus:before {content: "\e96d";}
.icon-folder-download:before {content: "\e96e";}
.icon-folder-upload:before {content: "\e96f";}
.icon-price-tag:before {content: "\e970";}
.icon-price-tags:before {content: "\e971";}
.icon-barcode:before {content: "\e972";}
.icon-qrcode:before {content: "\e973";}
.icon-ticket:before {content: "\e974";}
.icon-cart:before {content: "\e975";}
.icon-coin-dollar:before {content: "\e976";}
.icon-coin-euro:before {content: "\e977";}
.icon-coin-pound:before {content: "\e978";}
.icon-coin-yen:before {content: "\e979";}
.icon-credit-card:before {content: "\e97a";}
.icon-calculator:before {content: "\e97b";}
.icon-lifebuoy:before {content: "\e97c";}
.icon-phone-g:before {content: "\e97d";}
.icon-phone-hang-up:before {content: "\e97e";}
.icon-address-book:before {content: "\e97f";}
.icon-envelop:before {content: "\e980";}
.icon-pushpin:before {content: "\e981";}
.icon-location:before {content: "\e982";}
.icon-location2:before {content: "\e983";}
.icon-compass:before {content: "\e984";}
.icon-compass2:before {content: "\e985";}
.icon-map:before {content: "\e986";}
.icon-map2:before {content: "\e987";}
.icon-history:before {content: "\e988";}
.icon-clock:before {content: "\e989";}
.icon-clock2:before {content: "\e98a";}
.icon-alarm:before {content: "\e98b";}
.icon-bell:before {content: "\e98c";}
.icon-stopwatch:before {content: "\e98d";}
.icon-calendar:before {content: "\e98e";}
.icon-printer-g:before {content: "\e98f";}
.icon-keyboard:before {content: "\e990";}
.icon-display:before {content: "\e991";}
.icon-laptop:before {content: "\e992";}
.icon-mobile:before {content: "\e993";}
.icon-mobile2:before {content: "\e994";}
.icon-tablet:before {content: "\e995";}
.icon-tv:before {content: "\e996";}
.icon-drawer:before {content: "\e997";}
.icon-drawer2:before {content: "\e998";}
.icon-box-add:before {content: "\e999";}
.icon-box-remove:before {content: "\e99a";}
.icon-download:before {content: "\e99b";}
.icon-upload:before {content: "\e99c";}
.icon-floppy-disk:before {content: "\e99d";}
.icon-drive:before {content: "\e99e";}
.icon-database:before {content: "\e99f";}
.icon-undo:before {content: "\e9a0";}
.icon-redo:before {content: "\e9a1";}
.icon-undo2:before {content: "\e9a2";}
.icon-redo2:before {content: "\e9a3";}
.icon-forward:before {content: "\e9a4";}
.icon-reply:before {content: "\e9a5";}
.icon-bubble:before {content: "\e9a6";}
.icon-bubbles:before {content: "\e9a7";}
.icon-bubbles2:before {content: "\e9a8";}
.icon-bubble2:before {content: "\e9a9";}
.icon-bubbles3:before {content: "\e9aa";}
.icon-bubbles4:before {content: "\e9ab";}
.icon-user:before {content: "\e9ac";}
.icon-users:before {content: "\e9ad";}
.icon-user-plus:before {content: "\e9ae";}
.icon-user-minus:before {content: "\e9af";}
.icon-user-check:before {content: "\e9b0";}
.icon-user-tie:before {content: "\e9b1";}
.icon-quotes-left:before {content: "\e9b2";}
.icon-quotes-right:before {content: "\e9b3";}
.icon-hour-glass:before {content: "\e9b4";}
.icon-spinner:before {content: "\e9b5";}
.icon-spinner2:before {content: "\e9b6";}
.icon-spinner3:before {content: "\e9b7";}
.icon-spinner4:before {content: "\e9b8";}
.icon-spinner5:before {content: "\e9b9";}
.icon-spinner6:before {content: "\e9ba";}
.icon-spinner7:before {content: "\e9bb";}
.icon-spinner8:before {content: "\e9bc";}
.icon-spinner9:before {content: "\e9bd";}
.icon-spinner10:before {content: "\e9be";}
.icon-spinner11:before {content: "\e9bf";}
.icon-binoculars:before {content: "\e9c0";}
.icon-search-g:before {content: "\e9c1";}
.icon-zoom-in:before {content: "\e9c2";}
.icon-zoom-out:before {content: "\e9c3";}
.icon-enlarge:before {content: "\e9c4";}
.icon-shrink:before {content: "\e9c5";}
.icon-enlarge2:before {content: "\e9c6";}
.icon-shrink2:before {content: "\e9c7";}
.icon-key:before {content: "\e9c8";}
.icon-key2:before {content: "\e9c9";}
.icon-lock:before {content: "\e9ca";}
.icon-unlocked:before {content: "\e9cb";}
.icon-wrench:before {content: "\e9cc";}
.icon-equalizer:before {content: "\e9cd";}
.icon-equalizer2:before {content: "\e9ce";}
.icon-cog:before {content: "\e9cf";}
.icon-cogs:before {content: "\e9d0";}
.icon-hammer:before {content: "\e9d1";}
.icon-magic-wand:before {content: "\e9d2";}
.icon-aid-kit:before {content: "\e9d3";}
.icon-bug:before {content: "\e9d4";}
.icon-pie-chart:before {content: "\e9d5";}
.icon-stats-dots:before {content: "\e9d6";}
.icon-stats-bars:before {content: "\e9d7";}
.icon-stats-bars2:before {content: "\e9d8";}
.icon-trophy:before {content: "\e9d9";}
.icon-gift:before {content: "\e9da";}
.icon-glass:before {content: "\e9db";}
.icon-glass2:before {content: "\e9dc";}
.icon-mug:before {content: "\e9dd";}
.icon-spoon-knife:before {content: "\e9de";}
.icon-leaf:before {content: "\e9df";}
.icon-rocket:before {content: "\e9e0";}
.icon-meter:before {content: "\e9e1";}
.icon-meter2:before {content: "\e9e2";}
.icon-hammer2:before {content: "\e9e3";}
.icon-fire:before {content: "\e9e4";}
.icon-lab:before {content: "\e9e5";}
.icon-magnet:before {content: "\e9e6";}
.icon-bin:before {content: "\e9e7";}
.icon-bin2:before {content: "\e9e8";}
.icon-briefcase:before {content: "\e9e9";}
.icon-airplane:before {content: "\e9ea";}
.icon-truck:before {content: "\e9eb";}
.icon-road:before {content: "\e9ec";}
.icon-accessibility:before {content: "\e9ed";}
.icon-target:before {content: "\e9ee";}
.icon-shield:before {content: "\e9ef";}
.icon-power:before {content: "\e9f0";}
.icon-switch:before {content: "\e9f1";}
.icon-power-cord:before {content: "\e9f2";}
.icon-clipboard:before {content: "\e9f3";}
.icon-list-numbered:before {content: "\e9f4";}
.icon-list:before {content: "\e9f5";}
.icon-list2:before {content: "\e9f6";}
.icon-tree:before {content: "\e9f7";}
.icon-menu:before {content: "\e9f8";}
.icon-menu2:before {content: "\e9f9";}
.icon-menu3:before {content: "\e9fa";}
.icon-menu4:before {content: "\e9fb";}
.icon-cloud:before {content: "\e9fc";}
.icon-cloud-download:before {content: "\e9fd";}
.icon-cloud-upload:before {content: "\e9fe";}
.icon-cloud-check:before {content: "\e9ff";}
.icon-download2:before {content: "\ea00";}
.icon-upload2:before {content: "\ea01";}
.icon-download3:before {content: "\ea02";}
.icon-upload3:before {content: "\ea03";}
.icon-sphere:before {content: "\ea04";}
.icon-earth:before {content: "\ea05";}
.icon-link:before {content: "\ea06";}
.icon-flag:before {content: "\ea07";}
.icon-attachment:before {content: "\ea08";}
.icon-eye:before {content: "\ea09";}
.icon-eye-plus:before {content: "\ea0a";}
.icon-eye-minus:before {content: "\ea0b";}
.icon-eye-blocked:before {content: "\ea0c";}
.icon-bookmark:before {content: "\ea0d";}
.icon-bookmarks:before {content: "\ea0e";}
.icon-sun:before {content: "\ea0f";}
.icon-contrast:before {content: "\ea10";}
.icon-brightness-contrast:before {content: "\ea11";}
.icon-star-empty:before {content: "\ea12";}
.icon-star-half:before {content: "\ea13";}
.icon-star-full:before {content: "\ea14";}
.icon-heart-g:before {content: "\ea15";}
.icon-heart-broken:before {content: "\ea16";}
.icon-man:before {content: "\ea17";}
.icon-woman:before {content: "\ea18";}
.icon-man-woman:before {content: "\ea19";}
.icon-happy:before {content: "\ea1a";}
.icon-happy2:before {content: "\ea1b";}
.icon-smile:before {content: "\ea1c";}
.icon-smile2:before {content: "\ea1d";}
.icon-tongue:before {content: "\ea1e";}
.icon-tongue2:before {content: "\ea1f";}
.icon-sad:before {content: "\ea20";}
.icon-sad2:before {content: "\ea21";}
.icon-wink:before {content: "\ea22";}
.icon-wink2:before {content: "\ea23";}
.icon-grin:before {content: "\ea24";}
.icon-grin2:before {content: "\ea25";}
.icon-cool:before {content: "\ea26";}
.icon-cool2:before {content: "\ea27";}
.icon-angry:before {content: "\ea28";}
.icon-angry2:before {content: "\ea29";}
.icon-evil:before {content: "\ea2a";}
.icon-evil2:before {content: "\ea2b";}
.icon-shocked:before {content: "\ea2c";}
.icon-shocked2:before {content: "\ea2d";}
.icon-baffled:before {content: "\ea2e";}
.icon-baffled2:before {content: "\ea2f";}
.icon-confused:before {content: "\ea30";}
.icon-confused2:before {content: "\ea31";}
.icon-neutral:before {content: "\ea32";}
.icon-neutral2:before {content: "\ea33";}
.icon-hipster:before {content: "\ea34";}
.icon-hipster2:before {content: "\ea35";}
.icon-wondering:before {content: "\ea36";}
.icon-wondering2:before {content: "\ea37";}
.icon-sleepy:before {content: "\ea38";}
.icon-sleepy2:before {content: "\ea39";}
.icon-frustrated:before {content: "\ea3a";}
.icon-frustrated2:before {content: "\ea3b";}
.icon-crying:before {content: "\ea3c";}
.icon-crying2:before {content: "\ea3d";}
.icon-point-up:before {content: "\ea3e";}
.icon-point-right:before {content: "\ea3f";}
.icon-point-down:before {content: "\ea40";}
.icon-point-left:before {content: "\ea41";}
.icon-warning:before {content: "\ea42";}
.icon-notification:before {content: "\ea43";}
.icon-question:before {content: "\ea44";}
.icon-plus-g:before {content: "\ea45";}
.icon-minus:before {content: "\ea46";}
.icon-info:before {content: "\ea47";}
.icon-cancel-circle:before {content: "\ea48";}
.icon-blocked:before {content: "\ea49";}
.icon-cross:before {content: "\ea4a";}
.icon-checkmark:before {content: "\ea4b";}
.icon-checkmark2:before {content: "\ea4c";}
.icon-spell-check:before {content: "\ea4d";}
.icon-enter:before {content: "\ea4e";}
.icon-exit:before {content: "\ea4f";}
.icon-play2:before {content: "\ea50";}
.icon-pause:before {content: "\ea51";}
.icon-stop:before {content: "\ea52";}
.icon-previous:before {content: "\ea53";}
.icon-next:before {content: "\ea54";}
.icon-backward:before {content: "\ea55";}
.icon-forward2:before {content: "\ea56";}
.icon-play3:before {content: "\ea57";}
.icon-pause2:before {content: "\ea58";}
.icon-stop2:before {content: "\ea59";}
.icon-backward2:before {content: "\ea5a";}
.icon-forward3:before {content: "\ea5b";}
.icon-first:before {content: "\ea5c";}
.icon-last:before {content: "\ea5d";}
.icon-previous2:before {content: "\ea5e";}
.icon-next2:before {content: "\ea5f";}
.icon-eject:before {content: "\ea60";}
.icon-volume-high:before {content: "\ea61";}
.icon-volume-medium:before {content: "\ea62";}
.icon-volume-low:before {content: "\ea63";}
.icon-volume-mute:before {content: "\ea64";}
.icon-volume-mute2:before {content: "\ea65";}
.icon-volume-increase:before {content: "\ea66";}
.icon-volume-decrease:before {content: "\ea67";}
.icon-loop:before {content: "\ea68";}
.icon-loop2:before {content: "\ea69";}
.icon-infinite:before {content: "\ea6a";}
.icon-shuffle:before {content: "\ea6b";}
.icon-arrow-up-left:before {content: "\ea6c";}
.icon-arrow-up:before {content: "\ea6d";}
.icon-arrow-up-right:before {content: "\ea6e";}
.icon-arrow-right-g:before {content: "\ea6f";}
.icon-arrow-down-right:before {content: "\ea70";}
.icon-arrow-down:before {content: "\ea71";}
.icon-arrow-down-left:before {content: "\ea72";}
.icon-arrow-left-g:before {content: "\ea73";}
.icon-arrow-up-left2:before {content: "\ea74";}
.icon-arrow-up2:before {content: "\ea75";}
.icon-arrow-up-right2:before {content: "\ea76";}
.icon-arrow-right-g2:before {content: "\ea77";}
.icon-arrow-down-right2:before {content: "\ea78";}
.icon-arrow-down2:before {content: "\ea79";}
.icon-arrow-down-left2:before {content: "\ea7a";}
.icon-arrow-left-g2:before {content: "\ea7b";}
.icon-circle-up:before {content: "\ea7c";}
.icon-circle-right:before {content: "\ea7d";}
.icon-circle-down:before {content: "\ea7e";}
.icon-circle-left:before {content: "\ea7f";}
.icon-tab:before {content: "\ea80";}
.icon-move-up:before {content: "\ea81";}
.icon-move-down:before {content: "\ea82";}
.icon-sort-alpha-asc:before {content: "\ea83";}
.icon-sort-alpha-desc:before {content: "\ea84";}
.icon-sort-numeric-asc:before {content: "\ea85";}
.icon-sort-numberic-desc:before {content: "\ea86";}
.icon-sort-amount-asc:before {content: "\ea87";}
.icon-sort-amount-desc:before {content: "\ea88";}
.icon-command:before {content: "\ea89";}
.icon-shift:before {content: "\ea8a";}
.icon-ctrl:before {content: "\ea8b";}
.icon-opt:before {content: "\ea8c";}
.icon-checkbox-checked:before {content: "\ea8d";}
.icon-checkbox-unchecked:before {content: "\ea8e";}
.icon-radio-checked:before {content: "\ea8f";}
.icon-radio-checked2:before {content: "\ea90";}
.icon-radio-unchecked:before {content: "\ea91";}
.icon-crop:before {content: "\ea92";}
.icon-make-group:before {content: "\ea93";}
.icon-ungroup:before {content: "\ea94";}
.icon-scissors:before {content: "\ea95";}
.icon-filter:before {content: "\ea96";}
.icon-font:before {content: "\ea97";}
.icon-ligature:before {content: "\ea98";}
.icon-ligature2:before {content: "\ea99";}
.icon-text-height:before {content: "\ea9a";}
.icon-text-width:before {content: "\ea9b";}
.icon-font-size:before {content: "\ea9c";}
.icon-bold:before {content: "\ea9d";}
.icon-underline:before {content: "\ea9e";}
.icon-italic:before {content: "\ea9f";}
.icon-strikethrough:before {content: "\eaa0";}
.icon-omega:before {content: "\eaa1";}
.icon-sigma:before {content: "\eaa2";}
.icon-page-break:before {content: "\eaa3";}
.icon-superscript:before {content: "\eaa4";}
.icon-subscript:before {content: "\eaa5";}
.icon-superscript2:before {content: "\eaa6";}
.icon-subscript2:before {content: "\eaa7";}
.icon-text-color:before {content: "\eaa8";}
.icon-pagebreak:before {content: "\eaa9";}
.icon-clear-formatting:before {content: "\eaaa";}
.icon-table:before {content: "\eaab";}
.icon-table2:before {content: "\eaac";}
.icon-insert-template:before {content: "\eaad";}
.icon-pilcrow:before {content: "\eaae";}
.icon-ltr:before {content: "\eaaf";}
.icon-rtl:before {content: "\eab0";}
.icon-section:before {content: "\eab1";}
.icon-paragraph-left:before {content: "\eab2";}
.icon-paragraph-center:before {content: "\eab3";}
.icon-paragraph-right:before {content: "\eab4";}
.icon-paragraph-justify:before {content: "\eab5";}
.icon-indent-increase:before {content: "\eab6";}
.icon-indent-decrease:before {content: "\eab7";}
.icon-share-g:before {content: "\eab8";}
.icon-new-tab:before {content: "\eab9";}
.icon-embed:before {content: "\eaba";}
.icon-embed2:before {content: "\eabb";}
.icon-terminal:before {content: "\eabc";}
.icon-share-g2:before {content: "\eabd";}
.icon-mail:before {content: "\eabe";}
.icon-mail2:before {content: "\eabf";}
.icon-mail3:before {content: "\eac0";}
.icon-mail4:before {content: "\eac1";}
.icon-amazon:before {content: "\eac2";}
.icon-google:before {content: "\eac3";}
.icon-google2:before {content: "\eac4";}
.icon-google3:before {content: "\eac5";}
.icon-google-plus:before {content: "\eac6";}
.icon-google-plus2:before {content: "\eac7";}
.icon-google-plus3:before {content: "\eac8";}
.icon-hangouts:before {content: "\eac9";}
.icon-google-drive:before {content: "\eaca";}
.icon-facebook-g:before {content: "\eacb";}
.icon-facebook2:before {content: "\eacc";}
.icon-instagram-g:before {content: "\eacd";}
.icon-whatsapp:before {content: "\eace";}
.icon-spotify:before {content: "\eacf";}
.icon-telegram:before {content: "\ead0";}
.icon-twitter-g:before {content: "\ead1";}
.icon-vine:before {content: "\ead2";}
.icon-vk:before {content: "\ead3";}
.icon-renren:before {content: "\ead4";}
.icon-sina-weibo:before {content: "\ead5";}
.icon-rss-g:before {content: "\ead6";}
.icon-rss2:before {content: "\ead7";}
.icon-youtube-g:before {content: "\ead8";}
.icon-youtube2:before {content: "\ead9";}
.icon-twitch:before {content: "\eada";}
.icon-vimeo:before {content: "\eadb";}
.icon-vimeo2:before {content: "\eadc";}
.icon-lanyrd:before {content: "\eadd";}
.icon-flickr:before {content: "\eade";}
.icon-flickr2:before {content: "\eadf";}
.icon-flickr3:before {content: "\eae0";}
.icon-flickr4:before {content: "\eae1";}
.icon-dribbble:before {content: "\eae2";}
.icon-behance:before {content: "\eae3";}
.icon-behance2:before {content: "\eae4";}
.icon-deviantart:before {content: "\eae5";}
.icon-500px:before {content: "\eae6";}
.icon-steam:before {content: "\eae7";}
.icon-steam2:before {content: "\eae8";}
.icon-dropbox:before {content: "\eae9";}
.icon-onedrive:before {content: "\eaea";}
.icon-github:before {content: "\eaeb";}
.icon-npm:before {content: "\eaec";}
.icon-basecamp:before {content: "\eaed";}
.icon-trello:before {content: "\eaee";}
.icon-wordpress:before {content: "\eaef";}
.icon-joomla:before {content: "\eaf0";}
.icon-ello:before {content: "\eaf1";}
.icon-blogger:before {content: "\eaf2";}
.icon-blogger2:before {content: "\eaf3";}
.icon-tumblr:before {content: "\eaf4";}
.icon-tumblr2:before {content: "\eaf5";}
.icon-yahoo:before {content: "\eaf6";}
.icon-yahoo2:before {content: "\eaf7";}
.icon-tux:before {content: "\eaf8";}
.icon-appleinc:before {content: "\eaf9";}
.icon-finder:before {content: "\eafa";}
.icon-android:before {content: "\eafb";}
.icon-windows:before {content: "\eafc";}
.icon-windows8:before {content: "\eafd";}
.icon-soundcloud:before {content: "\eafe";}
.icon-soundcloud2:before {content: "\eaff";}
.icon-skype:before {content: "\eb00";}
.icon-reddit:before {content: "\eb01";}
.icon-hackernews:before {content: "\eb02";}
.icon-wikipedia:before {content: "\eb03";}
.icon-linkedin-g:before {content: "\eb04";}
.icon-linkedin2:before {content: "\eb05";}
.icon-lastfm:before {content: "\eb06";}
.icon-lastfm2:before {content: "\eb07";}
.icon-delicious:before {content: "\eb08";}
.icon-stumbleupon:before {content: "\eb09";}
.icon-stumbleupon2:before {content: "\eb0a";}
.icon-stackoverflow:before {content: "\eb0b";}
.icon-pinterest:before {content: "\eb0c";}
.icon-pinterest2:before {content: "\eb0d";}
.icon-xing:before {content: "\eb0e";}
.icon-xing2:before {content: "\eb0f";}
.icon-flattr:before {content: "\eb10";}
.icon-foursquare:before {content: "\eb11";}
.icon-yelp:before {content: "\eb12";}
.icon-paypal:before {content: "\eb13";}
.icon-chrome:before {content: "\eb14";}
.icon-firefox:before {content: "\eb15";}
.icon-IE:before {content: "\eb16";}
.icon-edge:before {content: "\eb17";}
.icon-safari:before {content: "\eb18";}
.icon-opera:before {content: "\eb19";}
.icon-file-pdf:before {content: "\eb1a";}
.icon-file-openoffice:before {content: "\eb1b";}
.icon-file-word:before {content: "\eb1c";}
.icon-file-excel:before {content: "\eb1d";}
.icon-libreoffice:before {content: "\eb1e";}
.icon-html-five:before {content: "\eb1f";}
.icon-html-five2:before {content: "\eb20";}
.icon-css3:before {content: "\eb21";}
.icon-git:before {content: "\eb22";}
.icon-codepen:before {content: "\eb23";}
.icon-svg:before {content: "\eb24";}
.icon-IcoMoon:before {content: "\eb25";}
