//
// Commonly/Basic Mixin's
//


//
// Full block
// --------------------------------------------------

@mixin block-full {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


//
// Placeholder text
// --------------------------------------------------



@mixin placeholder-text {
  &::-moz-placeholder {
    @content;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}


//
// Clear psuedo
//

@mixin clear-before-after{

	&:before,
	&:after{
		content: '';
		clear:both;
		display: table;
		table-layout:fixed;
	}
}

@mixin clear-after{
	&:after{
		content: ' ';
		clear:both;
		display: table;
		table-layout:fixed;
	}
}


//
// caret
//

@mixin down-caret($color: $black,$l:8px,$r: 8px,$t: 8px){
	width: 0;
	height: 0;
	border-left: $l solid transparent;
	border-right: $r solid transparent;
	border-top: $t solid $color;
	display:inline-block;
	vertical-align: middle;
}

