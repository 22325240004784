.footer-social-area {
  border-right: #00708f dashed 1px;
  padding-bottom: 1em;
  margin-bottom: 1em;
  padding-top: .7em;
}

.footer-border-bottom:before {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  width: calc(100% + .875em);
  height: 0px;
  margin-left: -.875em;
  border-bottom: #00708f dashed 1px;
}

.inline-block-cols > .office-specific-dept {
  vertical-align: bottom;
  line-height: 1.3;
}

.after-offices-area-border {
  margin-bottom: 1.25em;
}

.footer-contact-area .text-content {
  font-size: 0.875em;
}

.social-footer-zone {
  max-width: 100px;
  .acr-icon {
    font-size: 2.25rem;
    width: 1em;
    height: 1em;
    margin-top: 1.25rem;
    margin-right: 0;
    padding-top: 0;
    &:before {
      display: inline-block;
      vertical-align: top;
      line-height: 1.3;
    }
    &:not(.youtube):before {
      font-size: .75em;
    }
  }
  .icon-youtube:before {
    font-size: .815em;
    line-height: 1.2;
  }
  .acr-icon:hover {
    &:before {
      color: $white;
    }
    box-shadow: 0 0 0.1em rgba(255, 255, 255, 0.3);
    text-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
  }
}

.icon-facebook {
  background: #244786;
}

.icon-linkedin {
  background: #319ad1;
}

.icon-twitter {
  background: #00aee9;
}

.icon-instagram, .icon-youtube {
  background: #ca0905;
}

.icon-rss {
  background: #f95400;
}

.icon-blogger {
  background: #f4811f;
}

/*#Share this unique#*/

.share-drawer {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  top: -9999em;
  display: block;
  text-align: center;
  padding: 0;
  color: $white;
  background: $color-lt-gray-2;
  overflow: hidden;
  opacity: 0.1;
  height: 0;
  min-height: 0;
  width: 272px;
  transition: 200ms opacity ease-out, 200ms min-height ease-out;
  z-index: 900;
  margin-top: 1.75em;
  margin-left: .5ex;
  box-shadow: 0 4px 9px rgba($black, 0.25);
  .st-btn {
    margin: 4px !important;
  }
}

.share-content-inner {
  opacity: 0.1;
  -webkit-transition: 220ms all ease-in;
  transition: 220ms all ease-in;
}

.share-enabled .share-content-inner {
  opacity: 1;
}

.share-text:after {
  content: '';
  display: block;
  clear: both;
}

.share-link.share-enabled .share-drawer {
  top: 100%;
  padding: 1rem;
  overflow: visible;
  height: auto;
  opacity: 1;
  white-space: nowrap;
}

.share-drawer {
  left: 50%;
  transform: translateX(-50%);
  &.share-on-edge {
    left: auto;
    right: 0;
    transform: translateX(0);
    &:before {
      border-right: 1rem solid transparent;
      border-bottom: 1.625rem solid $color-lt-gray-2;
      left: auto;
      right: .5rem;
      margin-left: -1.1rem;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-bottom: 1.25rem solid $color-lt-gray-2;
    border-left: 1.1rem solid transparent;
    border-right: 1.1rem solid transparent;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -1.1rem;
  }
}

.notification-bar {
  background: $color-orange;
  position: relative;
  padding: 8px 12px;
  margin: 12px 0 22px;
  text-align: center;
  .notification-bar-text {
    margin: 8px 0;
  }
  .icon-notify-bell {
    display: inline-block;
    font-size: 24px;
  }
}

#dismissible-notification {
  display: none;
}

@include breakpoint-min(lg){
  .notification-bar {
    padding-left: 68px;
    padding-right: 112px;
    text-align: left;
    margin-left: 46px;
    margin-right: 46px;
    min-height: 50px;
    .notification-bar-text {
      margin: 8px 0;
    }
    .icon-notify-bell {
      position: absolute;
      left: 27px;
      top: 50%;
      transform: translateY(-50%);
    }
    .btn-notification-close {
      position: absolute;
      left: auto;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
    }
  }
}

@include breakpoint-max(md){
  .social-footer-zone .acr-icon, .footer-engage-graphic {
    font-size: 3.125em;
    margin-top: 1.2rem;
  }

  .social-footer-zone {
    margin: auto;
  }

  .footer-social-area h3 {
    font-weight: 700;
    font-size: .875em;
    font-family: inherit;
  }
}
