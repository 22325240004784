/*#colorbox overlay#*/
/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/

#colorbox, #cboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  transform: translate3d(0, 0, 0);
}

#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  transform: translate3d(0, 0, 0);
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  img, a {
    max-width: 100%;
  }
  iframe {
    width: 100%;
  }
}


#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
}


// User Style:
// Change the following styles to modify the appearance of Colorbox.  They are
// ordered & tabbed in a way that represents the nesting of the generated HTML.


#cboxOverlay {
  background: $black;
  -webkit-opacity: 0.6 !important;
  opacity: 0.6 !important;
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin-top: 42px;
  background: $black;
}

.cboxIframe {
  background: $white;
}

#cboxError {
  padding: 50px;
  border: 1px solid $color-gray;
}

#cboxLoadedContent {
  padding: 1em;
  background: $white;
}

#cboxTitle {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background: white;
	padding: .75em 1rem;
	font-size: 1.125rem;
	text-align: center;
	border-top: 1px solid $color-gray;
}

#cboxCurrent {
  position: absolute;
  bottom: 1em;
  left: 1em;
}

#cboxLoadingGraphic {
  background: url(/images/loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
  &:active {
    outline: 0;
  }

  &:hover:before {
    color: #1789a7;
  }
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: $white;
}

#cboxPrevious:before, #cboxNext:before {
  text-indent: 0;
  float: left;
  display: inline-block;
  color: $color-sky;
  font-size: 1.25rem;
}

#cboxClose:before {
  text-indent: 0;
  float: left;
  display: inline-block;
  color: $color-sky;
  font-size: 1.25rem;
  font-size: .875rem;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext {
  background-position: top right;
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxClose {
  position: absolute;
  top: 6px;
  right: 4px;
  display: block;
  width: 24px;
  height: 35px;
  text-indent: -9999px;
  background-position: center 8px;
}

@media only screen and (min-width: 640px) {
  #cboxLoadedContent {
    padding: 2rem 2rem 2rem;
  }
}
