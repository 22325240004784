.tool-tip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  &:after {
    margin-left: .5ex;
  }
}

.tip-text {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 100%;
  display: block;
  text-align: center;
  padding: 0;
  color: $white;
  background: $color-navy-2;
  overflow: hidden;
  opacity: 0;
  height: 0;
  min-height: 0;
  width: 5px;
  transition: 200ms opacity ease-out, 200ms min-height ease-out;
  z-index: 900;
  margin-bottom: 1.75em;
  margin-left: .5ex;
}

.tip-enabled .tip-text {
  width: 300px;
}

.tip-text-inner {
  opacity: 0;
  -webkit-transition: 220ms all ease-in;
  transition: 220ms all ease-in;
}

.tip-enabled .tip-text-inner {
  opacity: 1;
}

.tip-text:after {
  content: '';
  display: block;
  clear: both;
}

.tool-tip.tip-enabled .tip-text {
  padding: 1rem;
  overflow: visible;
  height: auto;
  opacity: 1;
  white-space: normal;
}

.tip-text {
  left: 50%;
  transform: translateX(-50%);
  &.tip-on-edge {
    left: auto;
    right: 0;
    transform: translateX(0);
    &:before {
      border-right: 1.1rem solid transparent;
      border-right: none;
      border-top: 1.625rem solid $color-navy-2;
      left: auto;
      right: .5rem;
      margin-left: -1.1rem;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 1.25rem solid $color-navy-2;
    border-left: 1.1rem solid transparent;
    border-right: 1.1rem solid transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1.1rem;
  }
}

label .tool-tip, .label-space .tool-tip {
  float: right;
}

.boxed-input .tool-tip {
  position: absolute;
  right: .625rem;
  top: .4rem;
}
