//
//Image Right and Left
//---------------------


@mixin imageLR($bkpt, $pad: 1rem){

	.img-right {
		&.#{$bkpt}wide-1,
		&.#{$bkpt}wide-2,
		&.#{$bkpt}wide-3,
		&.#{$bkpt}wide-4,
		&.#{$bkpt}wide-5,
		&.#{$bkpt}wide-6,
		&.#{$bkpt}wide-7,
		&.#{$bkpt}wide-8,
		&.#{$bkpt}wide-9,
		&.#{$bkpt}wide-10,
		&.#{$bkpt}wide-11,
		&.#{$bkpt}wide-12 {
			float: right;
			display: inline-block;
			padding-left: $pad;
		}
	}

	.img-left {
		&.#{$bkpt}wide-1,
		&.#{$bkpt}wide-2,
		&.#{$bkpt}wide-3,
		&.#{$bkpt}wide-4,
		&.#{$bkpt}wide-5,
		&.#{$bkpt}wide-6,
		&.#{$bkpt}wide-7,
		&.#{$bkpt}left-8,
		&.#{$bkpt}wide-9,
		&.#{$bkpt}wide-10,
		&.#{$bkpt}wide-11,
		&.#{$bkpt}wide-12 {
			float: left;
			display: inline-block;
			padding-right: $pad;
		}
	}

}
