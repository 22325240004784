
.rec-read-tile,
.archive-tile{
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 480px;
	border: 1px solid $color-gray;
	figure{
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom:100%;
		overflow: hidden;
		> a {
			position: absolute;
			display: block;
			width:100;
			@include block-full();
		}
		img{width:100%;}
	}
	footer{
		background: $color-navy;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		text-align: center;
		padding: .75rem $col-gutter;
		position: absolute;
		bottom:0;
		left:0;
		right:0;
		top:auto;
		min-height: px-to-rem(82px);

		a{
			font-size: 1.375rem;
			color:$white;
			font-family: $font-headings;
			line-height: 1.3;
		}
	}
}

.archive-tile{
	figure{
		padding-bottom: 128%;
	}
	footer{
		padding: 1rem $col-gutter;
		background: rgba($color-navy,.9);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top:auto;
	}
}
