.toolkit header {
  position: relative;
  padding-right: 6rem;
  .share-link {
    position: absolute;
    right: 0.1rem;
    top: auto;
    bottom: 1em;
  }
}

.toolkit-hero {
  margin-bottom: 1em;
}

.toolkit-cols {
  margin-top: 1em;
  > {
    .toolkit-item {
      margin-bottom: 0;
    }
    section, div, aside {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }
}

.toolkit-item {
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
  min-height: 5.5rem;
  vertical-align: middle;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.toolkit-icon {
  display: block;
  margin: auto;
  text-align: center;
  img {
    max-width: 72px;
    display: inline-block;
    margin-bottom: 1em;
    max-height: 53.5px;
  }
}

.toolkit-cols > .toolkit-item:last-child:before, .toolkit-item + .toolkit-item:before {
  content: '';
  display: block;
  height: 1em;
  border-top: 1px solid $color-lt-gray-2;
  margin-left: 1em;
  margin-right: 1em;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1.7em);
}

.toolkit-icon:hover {
  filter: brightness(90%);
}

@include breakpoint-min(xl){
  .toolkit-cols .toolkit-item {
    padding-left: .7em;
    padding-bottom: .125em;
  }

  .toolkit-item {
    &:before {
      content: none;
    }
    border: none;
    border-left: 1px solid $color-lt-gray-2;
    border-right: 1px solid $color-lt-gray-2;
    + .toolkit-item {
      border-left: 0;
      &:before {
        content: none;
      }
    }
  }

  .toolkit-cols > .toolkit-item:last-child:before {
    content: none;
  }

  .toolkit-icon {
    position: static !important;
  }

}

@include breakpoint-max(md){
  .toolkit-cols > .toolkit-item:last-child {
    margin-top: 1em;
    border-left: none;
    padding-top: 1.4em;
  }

  .toolkit-item + .toolkit-item {
    &:before {
      content: none;
    }
    border-left: 1px solid $color-lt-gray-2;
  }
}

@include breakpoint-min(md){
  .toolkit-item { padding-left: 88px !important; }
  .toolkit-icon { position: absolute; left: 0; display: block; }
}
