

.hero-img-mb,
.hero-img-dk{
	background-position: center center;
	background-size: cover;
	box-sizing: content-box;
	height: 100%;
	width:100%;

}

.hero-img-dk{
	display: none;
}

.hero-img-link{
	display: block;
	position: absolute;
	@include block-full();
}

@include breakpoint-min(lg){
	.hero-img-mb{
		display: none;
	}

	.hero-img-dk{
		display: block;
		position: absolute;
		@include block-full();
	}
}


.bulletin-hero-jump-button{
	position: absolute;
	top:auto;
	bottom:0;
	left:50%;
	transform: translateX(-50%);
	line-height: 1;
	z-index: 20;
	font-size:1.875rem;
	background: $white;
	padding: 0.125rem .75rem;
	.acr-icon{
		&:before{
			display: inline-block;
			transform: rotate(90deg);
		}
	}

	animation: down-arrow-animation 800ms ease-in-out infinite;
}

.bulletin-pg-article-hero{
	width: 100%;
	padding-bottom: 60%;

	@include breakpoint-min(md){
		// padding-bottom: 368px;
		padding-bottom: 40%;
		// border:1px dotted hotpink;
	}
	@include breakpoint-min(xl){
		padding-bottom: px-to-rem(480px);
	}
	position: relative;
	overflow: hidden;

	.content{
		position: absolute;
		width:100%;
		top:auto;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		z-index: 12;
	}

	&:after{
		content: ' ';
		position: absolute;
		display: block;
		z-index: 10;
		bottom:0;
		top:auto;
		left:0;
		right:0;
		height: 12rem;
		background: linear-gradient(
			to bottom,
			rgba($white,0) 0%,
			rgba($white,.5) 30%,
			rgba($white,1) 90%
		);
	}

	h1{
		margin-bottom: px-to-rem(30px);
		text-shadow: 0 0 .3rem rgba($white,.8);
	}

	.hero-img-mb{
		@include block-full();
	}
}


.bulletin-feat-article-hero{
	margin: 1rem 0 2.5rem;
	position: relative;
	margin-left: -($col-gutter);
	margin-right: -($col-gutter);

	.bulletin-feat-content{
		position: relative;
		z-index: 10;
		float: right;
		align-self:flex-end;
		color: $white;
		padding: 1.25rem 1.25rem px-to-rem(42px);
		background: $color-navy;
		margin-bottom: 1.625rem;

		.news-type-tag{
			bottom:100%;
			top:auto;
		}

		h2{
			font-size: px-to-rem(36px);
			margin: 0 0 .4em;
			color: $white;
		}

	}

	@include breakpoint-min(lg){
		margin-left: 0;
		margin-right: 0;
		display: flex;
		align-content: flex-end;
		justify-content: flex-end;
		padding: px-to-rem(72px) $col-gutter px-to-rem(62px);

		.bulletin-feat-content{
			left: -($col-gutter);
			margin-bottom:0;
		}

		.hero-img-mb{
			display: none;
		}

		.hero-img-dk{
			display: block;
			position: absolute;
			@include block-full();
		}

		.bulletin-feat-content{
			width: 40%;
		}
	}

	@include breakpoint-min(xl){
		.bulletin-feat-content{
			width: 30%;
		}
	}
}
.bulletin-pg-article-hero-subtitle{
	color: $color-navy;
	font-size: 1.5rem;
	line-height: 1.3;
	font-family: $font-freight-sans;
}

.bulletin-pg-article-basic-hero{
	text-align: center;
	position: relative;
	margin-bottom: 1rem;

	h1{
		margin-bottom:.2em;
	}
	figure{
		display: inline-block;
		// width: px-to-rem(500px + ($col-gutter * 2) );
		// max-width: 80%;
		img{
			max-width: 100%;
		}
		// @include breakpoint-min(xl){
		// 	width: 50%;
		// }
	}

	.bulletin-hero-jump-button{
		bottom: -1rem;
	}

	&-subtitle{
		display: inline-block;
		max-width: 100%;
		color: $color-navy;
		font-size: 1.375rem;
		margin: 1.25rem 0 .75rem;
		text-align:left;
	}
}


@keyframes down-arrow-animation{
	0%{
		transform: translate3d(-50%,0,0);
	}

	25%{
		transform: translate3d(-50%,4px,0);
	}

	75%{
		transform: translate3d(-50%,-1px,0);
	}
}
