.tabs-rotating ul {
  display: inline-block;
  padding: 0;
  margin-bottom: 1em;
  white-space: nowrap;
}

.tabs.tabs-evenly-spaced ul {
  width: 100%;
  max-width: 34rem;
  margin-left: auto;
  margin-right: auto;
}

.tabs-rotating li {
  display: inline-block;
  text-align: center;
  margin-right: 1rem;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
}

.tabs-evenly-spaced li {
  margin: 0 !important;
}

.tabs-rotating li.ellipsis:not(.active) {
  opacity: .4;
}

.tabs ul {
  white-space: nowrap;
  display: inline-block;
  float: none;
  width: auto;
}

.tabs-header li {
  display: inline-block;
}

.tabs-rotating a, .tabs-header a {
  padding: .5em 0;
  display: inline-block;
  font-size: 1.25em;
  color: $color-green;
  text-decoration: none;
  line-height: 1.3;
  position: relative;
}

.tabs-rotating {
  width: 100%;
  li:first-child {
    margin-left: 0;
  }
  text-align: center;
  ul {
    text-align: center;
  }
  a {
    text-align: center;
    border-bottom: #ebebeb solid 2px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-block;
  }
}

.tabs-header li {
  border-bottom: #ebebeb solid 1px;
}

.tabs-rotating .active a,
.tabs-header .active a {
  border-color: $color-green;
  color: $white;
  background: $color-green;
}


.acr-tabs .active a,
.tabs-rotating .active a,
.tabs-header .active a {
	&:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e903";
    color: $color-green;
    position: absolute;
    top: 100%;
    font-size: 1rem;
    margin-top: -3px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tabs-rotating {
  a span {
    display: inline-block;
  }
  // .active a:after {
  //   font-family: 'icomoon' !important;
  //   speak: none;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-variant: normal;
  //   text-transform: none;
  //   line-height: 1;
  //   content: "\e903";
  //   color: $color-green;
  //   position: absolute;
  //   top: 100%;
  //   font-size: 1rem;
  //   margin-top: -3px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}

// .acr-tabs .active a:after {
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   content: "\e903";
//   color: $color-green;
//   position: absolute;
//   top: 100%;
//   font-size: 1rem;
//   margin-top: -3px;
//   left: 50%;
//   transform: translateX(-50%);
// }

.news-slide {
  padding-left: 2px;
  padding-right: 2px;
}

.news-box3 {
  max-width: 298px;
}

.news-box4 {
  max-width: 225px;
}

.blue-link {
  color: $color-sky !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.news-box {
  border: 1px solid #e5e5e6;
  position: relative;
  width: 100%;
  float: none;
  margin: auto;
  &:hover {
    border-color: $color-orange;
  }
  .text-content {
    line-height: 1.8;
    padding-top: .7em;
  }
}

.news-type-tag {
  font-family: 'tk-freight-sans-pro-n4', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: .875em;
  background: $color-orange;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em 1em;
  line-height: 1.2;
  color: $white;
  z-index: 200;
}

.news-image {
  position: relative;
  img {
    max-width: 100%;
    width: 100%;
  }
}

.news-display-header {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1em;
  background: rgba(10, 65, 99, 0.9);
}

.news-display-header2 {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  padding: 0 1em;
  background: rgba(10, 65, 99, 0.9);
  z-index: 1;
}

.news-box {
  h3 {
    color: $white;
    font-size: 20px;
    font-size: 1.25rem;
    margin: .7em 0 .9em;
  }
  footer {
    background: $color-navy;
    padding: 1em;
    a {
      color: $color-sky;
      text-decoration: none;
      &:hover {
        color: #4fc3e3;
      }
    }
  }
}

#home-news-slider .slick-dots {
  margin-top: 1.2em;
}

.news-box-v2 {
  .news-display-header {
    bottom: auto;
    top: 0;
  }
  .news-type-tag {
    width: 100%;
  }
}

.news-slider-nav {
  padding: 0 2em;
  margin: 1.2em 0 2em;
  display: inline-block;
  width: auto;
  text-align: center;
  position: relative;
  .slick-dots {
    margin-top: 0;
  }
  .slick-arrow {
    border: 0;
    background: none;
    width: 1em;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
  }
  .slick-next {
    left: auto;
    right: 0;
  }
  .slick-arrow:hover {
    color: $color-green;
  }
}

.news-box {
  a {
    color: inherit;
    .overlay-to-show * {
      cursor: pointer;
    }
  }
  .overlay-to-show {
    text-align: left;
    padding-top: 1.8em;
    h3 {
      color: $color-navy-2;
    }
  }
}

.acr-tabs {
  width: 100%;
  display: block;
  list-style: none;
  margin-bottom: 1em;
  width: 100%;
  overflow: hidden;
  position: relative;
  color: $color-green;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 1em;
    width: 100%;
    border-bottom: 1px solid $color-lt-gray-2;
    margin-bottom: 1px;
    z-index: 1;
  }
  li {
    display: inline-block;
    list-style: inherit;
    text-align: center;
    transition: 100ms all ease-out;
    z-index: 300;
    padding-right: 2em;
    &.active {
      margin-left: 0 !important;
    }
    &.ellipsis {
      opacity: 0.45;
    }
  }
  a {
    padding: .5em 0;
    display: inline-block;
    font-size: .875em;
    color: $color-green;
    text-decoration: none;
    line-height: 1.3;
    position: relative;
    z-index: 100;
    border-bottom: 3px solid transparent;
  }
  .active a {
    border-bottom-color: $color-green;
    color: $color-font-body;
  }
}

.acr-tabs-body {
  border-bottom: 1px solid $color-lt-gray-2;
  padding-bottom: 1.25em;
}

.acr-tabs {
  > ul,
	.in-view-wrapper {
    padding-bottom: 1rem;
		> .in-view-wrapper{//for some reason it renders twice in nestled tabs
			padding-bottom:0;
			overflow: visible;
		}
  }
}

.box-account .acr-tabs-body {
  border-bottom: 0;
}


@include breakpoint-min(lg){
	.tabs-rotating li { margin-left: 2rem; }
	.tabs-rotating a { padding-left: 1.75rem; padding-right: 1.75rem; }
}
