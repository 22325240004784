#site-footer {
  color: $white;
  padding: 1.2em 0 2.5em;
  // background: -ms-linear-gradient(-150deg, rgb(0, 112, 143) 0%, rgb(10, 65, 99) 100%);
  // background: linear-gradient(to right -150deg, rgb(0, 112, 143) 0%, rgb(10, 65, 99) 100%);
  background: linear-gradient( -150deg, rgb(0, 112, 143) 0%, rgb(10, 65, 99) 100%);
  a {
    color: $white;
    &:hover {
      color: #efefef;
    }
  }
  * {
    color: $white;
  }
}

.office-specific-dept a {
  display: inline-block;
  > span {
    text-decoration: underline;
    display: inline-block;
    margin-right: 1ex;
  }
}

.footer-social-area h3 {
  margin: 0;
}

.footer-engage-graphic {
  margin-top: .77em;
}

.footer-bottom-copy {
  padding-top: 1.3em;
  .text-content {
    font-size: .8125em;
  }
  a {
    text-decoration: none;
  }
}

@include breakpoint-min(lg){

  .footer-offices-area, .footer-contact-area {
    padding-left: 3em !important;
  }

  .footer-bottom-copy {
    .xl-col-5 {
      margin-top: 3px;
    }
    a {
      display: inline-block;
      margin-left: .3rem;
      margin-right: .3rem;
    }
    li:last-child a {
      margin-right: 0;
    }
  }
}


@include breakpoint-max(md){
  .top-site-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .footer-offices-area {
    order: 1;
    padding-left: 1.75em;
    padding-right: 1.75em;
  }

  .after-offices-area-border {
    order: 2;
  }

  .footer-social-area {
    order: 3;
  }

  .footer-contact-area {
    order: 4;
    padding-top: .7em;
    padding-left: 1.25em;
  }

  .footer-engage-graphic {
    width: 1em;
  }
}


@include breakpoint-min(md){
  #footer-newsletter h2 { margin: 0; font-size: 2em; }
	#site-footer { padding-top: 1.6rem; }
  .footer-offices-area,  .footer-contact-area { padding-left: 3.75em; }
	.footer-office-item,  .footer-contact-area { padding-top: 1.2rem }
	.footer-social-area { padding-top: 1.2rem; }
	.footer-bottom-copy { padding-top: 3rem; }
}

@include breakpoint-min-max(md,lg){
// @media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer-engage-graphic { width: 1em; }
  .social-footer-zone .acr-icon,  .footer-engage-graphic { font-size: 3.25em; }
  .social-footer-zone { margin: auto; }
}

@include breakpoint-min-max(md,xl){
	.footer-offices-area,
  .footer-contact-area { padding-left: 2em }
}
