.inline-ul {
  ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.block-li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.inline-ul li {
  display: inline-block;
  list-style: inherit;
  width: auto;
  margin-bottom: 0;
}

.block-li li {
  display: block;
}

.has-no-bullet-ul {
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    list-style: inherit;
  }
}

// inline-block columns:
// blocks of content that cleverly reconfigure responsively if
// stacking is something necessary

.inline-block-cols {
  letter-spacing: -.3rem;
  /*removing white space so things line up with no space*/
  overflow: hidden;
  > {
    div, section {
      float: none;
      vertical-align: top;
      display: inline-block;
    }
  }
  * {
    letter-spacing: 0;
  }
}

// table-like:
// basically just makes an element appear like a table and its immediate child element

.table-like {
  display: table;
  table-layout: fixed;
  width: 100%;
  > {
    div, section, aside {
      display: table-cell;
      float: none;
    }
  }
  &:after {
    content: none;
  }
}

.untable-like {
  display: block;
  > {
    div, section {
      display: block;
      float: left;
    }
  }
  aside {
    display: block;
    float: left;
  }
}

.flex-container {
  display: flex !important;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  &.slick-slide {
    display: flex !important;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
  }
}

.flex-center{
	display: flex;
	flex-flow: row wrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.flex-left.slick-slide,
.space-between,
.tabs-evenly-spaced > ul,
.header-with-opts-links {
  display: flex !important;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
}

.flex-content-left, .flex-l {
  display: flex !important;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  flex-flow: row wrap;
}

.flex-container > section,
.flex-content-left > section,
.flex-container > div,
.flex-content-left > div,
.event-tile-area .flex-left > div,
.recent-news-slider .flex-left > div {
  margin-bottom: 1.75rem;
}

.flex-container > {
  aside.flex-content-left > aside {
    margin-bottom: 1.75rem;
  }
  .margin-bottom-0 {
    margin-bottom: 0;
  }
}

.flex-content-left > .margin-bottom-0,
.accordion .flex-container > div {
  margin-bottom: 0;
}

.tabs-evenly-spaced.only-one-tab > ul {
  justify-content: center;
  align-content: center;
}

.ways-learn {
  padding-top: .75rem;
}

.event-tile-area:after,
.recent-news-slider:after {
  content: '';
  display: block;
  clear: both;
}

.flex-container {
  &.row:after, &:after {
    content: none;
  }
}

.header-with-opts-links:after {
  content: none;
}

.space-between {
  align-items: baseline;
}

.flex-valign-mid, .flex-m {
  align-items: center;
}

.tabs-rotating > ul {
  flex-flow: nowrap;
}
