.header-share-opts {
	display: inline-block;
	margin-bottom: .5rem;
	position: relative;
	a {
		display: inline-block;
		margin-top: 0.2rem;
		&:not(:last-child) {
			margin-right: 1.4rem;
		}
	}
}

.share-link, .reccommend-link, .bookmark-link{
	color: $color-green;
	font-weight: 600;

	&:before {
		margin-right: .4ex;
		font-size: 1.1em;
		vertical-align: text-bottom;
	}

	&:hover{
		color: $color-dark-green;
	}
}
