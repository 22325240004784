
.text-orange, .text-orange a, #site-footer .text-orange a, #site-footer .text-orange * { color: $color-orange; }
.text-acr-light-blue, .button-block .icon-db-chevron2-right { color: $color-sky; }
.header-bottom-zero { bottom: 0 !important; top: inherit !important; z-index:1 }
.text-white { color: $white }
.text-green, a.text-green, .remove-item, .remove-item-save-later { color: $color-green }
.text-acr-blue { color: $color-navy-2; }
.text-facebook { color: #244786 }
.text-linkedin { color: #319ad1 }
.text-twitter { color: #00aee9 }
.text-instagram { color: #ca0905 }
.text-youtube { color: #ca0905 }
.text-rss { color: #f95400 }
.text-blogger { color: #f4811f }
a.text-green:hover, a.remove-item:hover, a.remove-item-save-later:hover { color: $color-dark-green }
.underline-orange { border-bottom: 1px solid $color-orange; padding-bottom: .3rem; }
.content .img-max-320 img { max-width: 320px; width: 100%; display: inline-block; }
.content .img-max-400 img { max-width: 400px; width: 100%; display: inline-block; }
