.mitHub, .mitHub-section-spacing {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mitHub-ImageWithText {
  margin-left: auto;
  margin-right: auto;
}

.mitHub header {
  position: relative;
  padding-right: 6rem;
  .share-link {
    position: absolute;
    right: 0.1rem;
    top: auto;
    bottom: 1em;
  }
}

.mitHub-rowTitle {
  font-family: 'freight-sans-pro';
  color: white;
  font-size: 42px;
  line-height: 45px;
  font-weight: 700;
}

.mitHub-cols > {
  .mitHub-item {
    margin-bottom: 0;
  }
  section, div, aside {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

.mitHub-item {
  padding-bottom: 1em;
  position: relative;
  min-height: 5.5rem;
  vertical-align: middle;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.mitHub-icon {
  display: block;
  margin: auto;
  text-align: center;
  img {
    max-width: 72px;
    display: inline-block;
    margin-bottom: 1em;
    max-height: 53.5px;
  }
}

.mitHub-ItemTitle {
  font-size: 30px;
  font-weight: 700;
}

.mitHub-cols {
  .icon-external-site:after, .icon-target-blank:before {
    content: none;
  }
  > .mitHub-item:last-child:before {
    content: '';
    display: block;
    height: 1em;
    border-top: 1px solid $color-lt-gray-2;
    margin-left: 1em;
    margin-right: 1em;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1.7em);
  }
}

.mitHub-item + .mitHub-item:before {
  content: '';
  display: block;
  height: 1em;
  border-top: 1px solid $color-lt-gray-2;
  margin-left: 1em;
  margin-right: 1em;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1.7em);
}

.mitHub-icon:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
@include breakpoint-max(md){
// @media only screen and (max-width: 640px) {
  .mitHub-cols > .mitHub-item:last-child {
    margin-top: 1em;
    border-left: none;
    padding-top: 1.4em;
  }
  .mitHub-item + .mitHub-item {
    &:before {
      content: none;
    }
    border-left: 1px solid $color-lt-gray-2;
  }
  .mitHub-ImageWithText {
    margin-left: auto;
    margin-right: auto;
    display: table;
    margin-bottom: 10px;
  }
  .mitHub-squareImage {
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    width: 200px;
    margin-bottom: 10px;
  }
  .mitHub-rowTitle {
    margin-bottom: 20px;
  }
  .mitHub-item {
    justify-content: center;
    display: grid;
  }
  .mitHub-ItemTitleDiv, .mitHub-itemSubTitleDiv, .mitHub-itemIconDiv {
    width: 100%;
  }
}
@include breakpoint-min(md){
// @media only screen and (min-width: 641px) {
  .mitHub-cols .mitHub-item {
    padding-left: 5px;
  }
  .mitHub-icon {
    position: absolute;
    right: 10px;
    display: block;
    top: 5px;
  }
  .mitHub-rowTitle, .mitHub-squareImage {
    margin-left: 10px;
  }
  .mitHub-ItemTitleDiv {
    width: 100%;
  }
  .mitHub-itemSubTitleDiv {
    width: 83%;
    float: left;
    padding-right: 10px;
  }
  .mitHub-itemIconDiv {
    width: 15%;
    float: left;
    margin-top: 2px;
  }
}
@include breakpoint-max(xl){
// @media only screen and (min-width: 961px) {
  /*#mitHub xl screen#*/
  .mitHub-cols .mitHub-item {
    padding-left: .7em;
    padding-bottom: .125em;
  }
  .mitHub-item {
    &:before {
      content: none;
    }
    + .mitHub-item {
      border-left: 0;
      &:before {
        content: none;
      }
    }
  }
  .mitHub-cols > .mitHub-item:last-child:before {
    content: none;
  }
  .mitHub-icon {
    position: static;
  }
  .mitHub-ItemTitleDiv, .mitHub-itemSubTitleDiv, .mitHub-itemIconDiv {
    width: 100%;
  }
}

@font-face {
    font-family: 'IcoMoon-V2';
    src: url('/fonts/IcoMoon-V2.ttf') format('truetype');
}

.acr-icon-v2 {
    font-family: 'IcoMoon-V2' !important;
    speak: none;
    font-style: normal;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    vertical-align: baseline;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    color: $color-orange;
    font-size: 2.5rem;
}

.icon-eye:before {
    content: "\e9ce";
}

.mitHub-icon-profie:before {
    content: "\E975"
}

.mitHub-icon-answer:before {
    content: "\E96C"
}

.mitHub-icon-tools:before {
    content: "\E991"
}

.mitHub-icon-engage:before {
    content: "\E972"
}

.mitHub-icon-meet:before {
    content: "\e9c4"
}

.mitHub-icon-share:before {
    content: "\EA82"
}

.mitHub-icon-jobs:before {
    content: "\E9AE"
}

.mitHub-icon-read:before {
    content: "\E91F"
}

.mitHub-icon-standards:before {
    content: "\E9B2"
}

.highlighted-icon {
    color: $color-orange;
}
