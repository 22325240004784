#main-nav-dropdown {
  position: relative;
  z-index: 1002;
  clear: both;
}

#main-nav ul ul, #main-nav-dropdown ul ul {
  display: none;
}

.main-dropdown-nav-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  Z-index: 1;
  background: #f6f6f6;
  padding-top: 0;
  padding-bottom: 0;
  ul {
    display: block;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

#main-nav-dropdown .show-level-4 ul {
  display: block;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-dropdown-nav-item {
  li {
    list-style: inherit;
    margin: 0;
    margin-bottom: .75em;
    padding: 0;
    display: inline-block;
    width: 100%;
    width: calc(100% + .75em);
    position: relative;
    overflow: visible;
  }
  h3 a {
    font-weight: 400;
  }
  a {
    color: $color-navy-2;
  }
}

.nav-enable-lvl:after, .enabled-lvl3-item .nav-enable-lvl.acr-icon:after {
  content: "\e91e";
  font-size: 0.75em;
}

.dropdown-nav-lvl-3 li {
  margin-bottom: .4rem;
}

.lvl3-btn:hover {
  color: #b1cee0;
}

.m-nav-btn {
  display: none;
  position: absolute;
  left: auto;
  right: 1em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.m-close-dropdown {
  width: 1.5rem;
  height: 1.5rem;
  color: #7d7d7d;
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1004;
  cursor: pointer;
  text-align: center;
  &:active, &:hover {
    color: #555;
  }
  &:before {
    font-size: .625rem;
  }
}

#main-nav {
  min-width: 100%;
  position: static;
  z-index: 2001;
}

.tablet-nav-back {
  color: $color-sky;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  .acr-icon {
    font-size: .6em;
    display: inline-block;
    margin-right: .7rem;
    margin-bottom: .3em;
    vertical-align: middle;
  }
}

.mobile-nav-back.acr-icon {
  color: $color-green;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1em;
  vertical-align: bottom;
  margin-right: .4em;
}

#m-nav-bottom {
  text-align: center;
  .acr-icon {
    margin-right: .8ex;
  }
}

#m-utility-top {
  li ~ li {
    border-left: 1px solid $white;
  }
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-right: .625rem;
  margin-left: .625rem;
}

#m-utility-nav-holder {
  padding-left: .875rem;
  padding-right: .875rem;
  &:before {
    content: '';
    display: block;
    height: 1em;
    border-top: 2px solid #e6e6e6;
    margin-top: 1rem;
  }
}

#m-utility-top {
  li a {
    color: $white;
    display: inline-block;
    &:hover {
      color: #ccdfeb;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: table;
    list-style: none;
    width: 100%;
  }
  li {
    display: table-cell;
    list-style: none;
    float: none;
    vertical-align: middle;
  }
}

#m-utility-bottom {
  margin-top: .5em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
  text-align: center;
  ul {
    width: 100%;
    display: block;
  }
  li {
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    width: 50%;
    display: block;
    float: left;
  }
}

#m-utility-top {
  a .acr-icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    float: none;
    display: block;
    font-size: 18px;
  }
  li a {
    font-size: 12px;
    padding: 0 !important;
    margin: 0;
  }
}

@media only screen and (min-width: 1025px) {
  main[role='main'] {
    margin-top: 231px;
  }
  #site-header {
    left: 0;
    top: 0;
    z-index: 1000;
    &.sticky {
      position: fixed;
    }
  }
  .sticky .stick-hide {
    display: none;
  }
  #header-logo {
    background-size: 124px;
    width: 124px;
    height: 57px;
  }
  .sticky {
    #header-search ul {
      padding-left: 96px;
    }
    #header-logo-sticky-srch {
      display: block;
      height: 36px;
      width: 80px;
      background-size: 80px 36px;
      margin-top: 2px;
      position: absolute;
    }
  }
  #mobile-nav-btn, #m-nav-bottom {
    display: none;
  }
  #main-nav-area {
    background: $color-navy-2;
    clear: both;
    position: relative;
    z-index: 2001;
    .content {
      position: static;
    }
  }
  #main-nav-holder > ul {
    > li {
      > a {
        display: inline-block;
        text-decoration: none;
        color: $white;
        padding: .8em 1em;
        position: relative;
        z-index: 1;
      }
      display: table-cell;
      vertical-align: middle;
      border-left: 1px solid #166b89;
      max-width: 213px;
      line-height: 1.3;
      border-collapse: collapse;
      position: static;
      &:hover, &.active {
        background: #f6f6f6;
      }
      &.on-page > a:after {
        content: '' !important;
        display: block;
        position: absolute;
        bottom: 5px !important;
        left: 12px;
        height: 5px;
        width: -webkit-calc(100% - 24px);
        width: calc(100% - 24px);
        background: #007190;
      }
      &:hover > a, &.active > a {
        color: #007190;
      }
    }
    display: table;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .main-nav-mega .has-children > a:after {
    content: "\e91e";
    display: inline-block;
    text-align: center;
    position: absolute;
    font-size: .5rem;
    left: auto;
    right: 11px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #main-nav-holder > ul > li.active.has-children > a:after {
    content: "\e903";
    display: inline-block;
    text-align: center;
    top: auto;
    bottom: 3px;
    left: 50%;
    font-size: .5rem;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  #main-nav {
    #main-nav-login {
      border-left: 0;
      background: $color-green;
      white-space: nowrap;
      &:hover {
        background: $color-dark-green;
        > a {
          color: $white;
        }
      }
      &.hover > a {
        color: $white;
      }
    }
    ul ul {
      opacity: 0;
      display: block;
      position: absolute;
      padding: 0;
      margin: 0;
      top: -9999em;
      left: -9999px;
      -webkit-transition: 120ms opacity ease-in-out;
      transition: 540ms opacity ease-in-out;
      width: 33.3333%;
      display: block;
      padding-left: 8px;
      li a {
        padding: 6px 8px;
        display: block;
        color: #007190;
        position: relative;
        &:hover {
          color: #65939f;
        }
      }
    }
  }
  .main-nav-mega {
    width: 100%;
    min-height: 12px;
    position: absolute;
    top: -9999em;
    left: -9999em;
    background: #f6f6f6;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
  .active .main-nav-mega {
    top: 100%;
    left: 0;
    z-index: 1010;
    width: 100%;
  }
  .main-nav-mega > .content {
    position: relative;
  }
  #main-nav {
    .active .main-nav-l2 {
      position: relative;
      left: 0;
      top: 0;
      opacity: 1;
      padding-bottom: 12px;
    }
    ul ul {
      .active > {
        ul {
          left: 100%;
          top: 0;
          opacity: 1;
          width: 100%;
          padding-bottom: 12px;
        }
        a {
          background-color: #ededed;
        }
      }
      .on-page > a {
        background-color: #ededed;
      }
    }
    h3 {
      margin: 0;
      min-height: 48px;
      padding-top: 8px;
    }
    ul ul li {
      display: block;
      text-align: left;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  .mobile-nav-only {
    display: none !important;
  }
}
