.callout-item figure img {
  width: 100%;
}

.register-callout, .media-contacts-box {
  border: 1px solid $color-gray;
}

.register-callout:after, .register-callout-text:after {
  content: '';
  display: block;
  clear: both;
}

.register-callout-header, .heading-bar {
  background: #225472;
  color: $white;
  padding: .8em 2em .95em;
}

.register-callout-header h3, .heading-bar h3 {
  margin: 0;
  color: $white;
}

.register-callout-header {
  h3 a {
    color: inherit;
  }
  .acr-icon {
    font-size: .6rem;
  }
}

.register-callout-text {
  padding: 1em 0em 1.3em;
  .text-content {
    font-weight: 600;
  }
}

.media-contact-item, .person-item {
  max-width: 224px;
  width: 100%;
  margin-bottom: 1rem;
}

.resources-slider-nav, .search-pager-nav {
  position: relative;
  display: block;
  width: 9em;
  float: none;
  margin: 2em auto 0;
  text-align: center;
  padding-right: 2em;
  padding-left: 2em;
}

.resources-slider-nav .slick-arrow, .search-pager-nav .slick-arrow {
  top: .125em;
  cursor: pointer;
}

.slick-custom-pager ul.slick-dots {
  display: none !important;
}

input[type=text].slide-num-input {
  width: 1.7em;
  min-height: unset;
  text-align: center;
  padding: .1em;
  margin-right: .2em;
}

.media-contacts-area > div {
  margin-top: 1.3rem;
  margin-bottom: 0;
}

@include breakpoint-min(lg){
	.callout-item{
     ~ .callout-item {
       border-left: 1px solid $color-orange;
     }
  }
}

@media only screen and (min-width: 1096px) {
	.callout-item {
    border-left: 1px solid $color-orange;
    ~ .callout-item {
      border-right: 1px solid $color-orange;
    }
  }
	.in-content-callout { max-width: 14em; float: right; }
}
